




































import Error from '@/components/Error.vue';
import { BaseComponent, Component } from '@/components/BaseComponent';

@Component({
  components: {
    Error,
  },
})
export default class TwoFactorAuthentication extends BaseComponent {
  identifiers: string[] = [];

  timeout = 0;

  seconds = 0;

  timer = 0;

  code = '';

  async sendCode(identifier: string) {
    this.error = null;
    const result = await this.pds.sendTwoFactorAuthenticationCode({ identifier });
    if (result.isFailure()) {
      this.error = result.error;
    } else {
      this.startTimer();
    }
  }

  async useCode() {
    this.error = null;
    const result = await this.pds.useTwoFactorAuthenticationCode({ code: this.code });
    if (result.isSuccess()) {
      this.stopTimer();
      this.router.changePage(result);
    } else {
      this.error = result.error;
    }
  }

  startTimer() {
    this.seconds = this.timeout;
    this.timer = setInterval(() => {
      this.seconds -= 1;
      if (this.seconds <= 0) {
        this.stopTimer();
      }
    }, 1000);
  }

  stopTimer() {
    this.error = null;
    clearInterval(this.timer);
    this.timer = 0;
  }

  // eslint-disable-next-line class-methods-use-this
  formatTime(timeInSeconds: number) {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  created() {
    const page = this.store.getPage(this.Pages.TwoFactorAuthentication);
    if (page) {
      this.identifiers = page.identifiers_2fa;
      this.timeout = page.resend_sec_2fa;
    } else {
      this.router.goTo(this.Pages.Login);
    }
  }
}
